import React, { useState } from 'react';
import './App.css';

function App() {
  const [files, setFiles] = useState([]);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    setFiles([...droppedFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const uploadFiles = async () => {
    const apiUrl = 'YOUR_API_URL'; // Replace with your API URL
    const formData = new FormData();

    files.forEach(file => {
      formData.append('images', file);
    });

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  return (
    <div 
      className="App"
      onDrop={handleDrop} 
      onDragOver={handleDragOver}
      style={{ width: '100%', height: '100vh', border: '2px dashed #ccc', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div>
        <h2>Drag and Drop Files Here</h2>
        <button onClick={uploadFiles}>Upload</button>
      </div>
    </div>
  );
}

export default App;